import React from 'react'
import Logo from '../components/Logo/Logo'
import Navbar from '../components/Navbar/Navbar'
import BlogContent from './../components/Blog/BlogContent';
import RightBar from '../components/RightBar/RightBar';
import Footer from '../components/Footer/Footer';
import { useMediaQuery } from 'react-responsive'
import NavbarMobile from '../components/Navbar/NavbarMobile';
import BlogContentMobile from '../components/Blog/BlogContentMobile';

const Home = () => {

    const STYLE = {
        width : '90%',
        height :'250vh',
        backgroundColor :'#DDEBEB',
        margin : '0 auto',
        marginTop :'0.5rem',
        borderRadius:'0.4rem',
        display:'flex',
        overflow: 'hidden',
        justifyContent:'space-between'
        
    }

   
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
      }

      const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
      }
     

    return (
        <>
        <Mobile>
            <NavbarMobile/>
                <BlogContentMobile/>
            <Footer/>
        </Mobile>
        <Desktop>
        <Logo/>
            <Navbar/>
            <div style={STYLE}>
                <RightBar/>
                <BlogContent/>
            </div>
            <Footer/>
        </Desktop>
            
        </>
    )
}

export default Home
