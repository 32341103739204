import React from "react";
import { Link } from "react-router-dom";
import "./RightBar.css";
import FacbookIcon from "./facebook.png";
import YoutubeIcon from "./youtube.png";

const RightBar = () => {
  return (
    <>
      <div className="RightBar-Container">
        <li>
          <Link to="/التدوينات">مقالات و بحوث الشيخ</Link>
        </li>
        <li>
          <Link to="/إسأل">أنت تسأل و الغريب يجيب</Link>
        </li>
        <li>
          <Link to="/الصوتيات">صوتيات الشيخ</Link>
        </li>
        <li>
          <Link to="/برامج">برامج علاجية</Link>
        </li>
        <li>
          <Link to="/فتاوى">فتاوى في الرقية الشرعية</Link>
        </li>

        <div className="RS-Icons">
          <a href="https://www.facebook.com/%D8%B5%D9%81%D8%AD%D8%A9-%D8%A7%D9%84%D9%85%D8%B9%D8%A7%D9%84%D8%AC-%D8%A7%D9%84%D8%BA%D8%B1%D9%8A%D8%A8-%D8%A7%D9%84%D9%85%D9%88%D8%B5%D9%84%D9%89-%D8%A7%D9%84%D8%B1%D8%B3%D9%85%D9%8A%D9%87-107382177886650">
            {" "}
            <img src={FacbookIcon} alt="" />{" "}
          </a>
          <a href="https://www.youtube.com/channel/UC_spC3-gxDJ5ree1-OTkkdw">
            {" "}
            <img src={YoutubeIcon} alt="" />{" "}
          </a>
        </div>
      </div>
    </>
  );
};

export default RightBar;
