const Style = {
    width: "90%",
    height: "190vh",
    backgroundColor: "#DDEBEB",
    margin: "0 auto",
    marginTop: "0.5rem",
    borderRadius: "0.4rem",
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
  };



export default Style ;