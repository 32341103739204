import React from 'react'

const Eroor404 = () => {
    return (
        <div style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}>
            <h1>This Page 404 ! </h1>
        </div>
    )
}

export default Eroor404
