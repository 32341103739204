import React, { useState } from "react";
import API from "../../../api/Api";
import "./styles.css";
import Image from './Tuto.PNG'

const AddAudio = () => {


  const [lien , setLien] = useState();
  const [messagevalid , setMessagevalid] = useState(false);
  const [messageerr , setMessageerr] = useState(false);
  const [playlist , setPlaylist] = useState()


  const Send = () => {
    const data = {lien , playlist}
    API.post('api/CreateAudio' , data)
    .then((res) => {
      if(res.data.err === true) {
        setMessageerr(res.data.message);
        setTimeout(() => {
          setMessageerr(false)
        });
      }else {
        setMessagevalid(res.data.message);
        setLien('')
        setTimeout(() => {
          setMessagevalid(false)
        } , 3000);
      }
    }).catch((err) => {
      setMessageerr(err.data.message);
    })
  }
  
  return (
    <>
     
      <div className="AddAudio-Container">
      {messagevalid && (
          <div className="MessageValid">
              <span> {messagevalid} </span>
          </div>
        )}
        {messageerr && (
          <div className="MessageError">
              <span> {messageerr} </span>
          </div>
        )}
        <div className="Title--">
          <h1>إضافة صوتية</h1>
        </div>
        <div className="Article-title">
          <label> رابط الصوتية</label>
          <input value={lien} onChange={(event) => setLien(event.target.value)} placeholder="... رابط الصوتية" />
        </div>
        <div className="Article-title">
          <select value={playlist} onChange={(event) => setPlaylist(event.target.value)} style={{width:'100%', borderRadius:'0.2rem' , border:'1px solid #559CA2' , height:'2rem' , textAlign:"end" , fontSize:'1rem'}}>
            <option value="" > نوع الرقية </option>
            <option value="al3yn" >رقية من العين</option>
            <option value="elshr" >رقية من الحسد</option>
            <option value="alhasd" >رقية من السحر</option>
          </select>
        </div>
        <div className="Btn-Submit">
            <button onClick={Send} type="submit">
            نشر 
            </button>
        </div>
        <div className="Image-Tuto">
            <img src={Image} alt="Tuto" />
        </div>
      </div>
    </>
  );
};

export default AddAudio;
