import React, { useState } from "react";
import Api from '../../../api/Api'

import "./styles.css";
const AddFatwa = () => {

    const [name , setName] = useState();
    const [article , setArticle] = useState();
    const [title , setTitle] = useState();
    const [messageerr , setMessageErr] = useState(false);
    const [messagevalid , setMessagevalid] = useState(false);



    const Send = () => {
      const formData = {name , article , title}
    Api.post("api/CreateFatwa" , formData)
    .then((res) => {
      if(res.data.err === true) {
        setTimeout(()=> {
          setMessageErr(false)
        } , 3000);
      }else {
        setMessagevalid(res.data.message)
        setName("");
        setArticle("");
        setTimeout(() => {
          setMessagevalid(false)
        } , 3000);
      }
    }).catch((err) => {
      console.log(err)
    });
    };
  return (
    <>
      <div className="Add-Article-Container">
        {messagevalid && (
          <div className="MessageValid">
              <span> {messagevalid} </span>
          </div>
        )}
        {messageerr && (
          <div className="MessageError">
              <span> {messageerr} </span>
          </div>
        )}
        <div className="Title--">
          <h1>إضافة الفتوى</h1>
        </div>
        <div className="Article-title">
          <label> صاحب الفتوى  </label>
          <input value={name} onChange={(event) => setName(event.target.value)} placeholder="صاحب الفتوى" />
        </div>
        <div className="Article-title">
          <label>  السؤال</label>
          <input value={title} onChange={(event) => setTitle(event.target.value)} placeholder="السؤال" />
        </div>
        <div className="Article-Bio">
          <label> الفتوى</label>
          <textarea value={article} onChange={(event) => setArticle(event.target.value)} placeholder="... المقالة" />
        </div>
        <div className="Btn-Submit">
            <button onClick={Send} type="submit">
            نشر 
            </button>
        </div>
      </div>
    </>
  );
};

export default AddFatwa;
