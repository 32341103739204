import React, { useState , useEffect } from 'react'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import API from '../../../api/Api';
import Loading from '../../loading/Loading';
const Faq = () => {

    const [name , setName] = useState();
    const [reponse , setreponse ] = useState();
    const [question , setQuestion] = useState();
    const [messagevalid , setMessagevalid] = useState(false);
    const [cards , setCards] = useState([]);
    const [loading , setLoading] = useState(false);
    const [message , setMessage] = useState()

    useEffect(() => {
        API.get("api/GetQuestions")
          .then((res) => {
            if (res.data.length === 0) {
              setMessage(" لا يوجد أسئلة ");
            } else {
              setCards(res.data.AllQuestions);
              setLoading(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

    const PostDelete = (_id) => {
        API.delete(`api/DeleteReponse/${_id}`).then(ok => {
            API.get("api/GetQuestions")
          .then((res) => {
            if (res.data.length === 0) {
              setMessagevalid(" تم الإجابة على السؤال ");
            } else {
              setCards(res.data.AllQuestions);
              setLoading(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        })
      };
    const Send = () => {
        const DATA = {name , reponse , question}
        API.post('api/Reponse' , DATA).then((res) => {
            if(res.data.err === false){
                setMessagevalid(res.data.message)
                setTimeout(() => {
                    setMessagevalid(false)
                  } , 3000);
                  setName('')
                  setreponse('')
                  setQuestion('')
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <>
         {messagevalid && (
          <div style={{position:'absolute' , width:'70%'}} className="MessageValid">
              <span> {messagevalid} </span>
          </div>
        )}


<div className="AllQuestions-Container">
{!message ? (
    <>
          {loading ? (
            cards.map((Asker) => (
                <div key={Asker._id} className="AllQuestion-Box">
                <div className="AllQuestion-Box-Informations">
                        <h5>     {Asker.question} : السؤال </h5>
                        <h5>  {Asker.name} : إسم السائل </h5>
                </div>
                <div onClick={() => {
                    PostDelete(Asker._id)
                }} className="AllQuestion-Box-Check">
                    <BsFillCheckCircleFill/>
                </div>
            </div>
            ))
          ) : (
            <>
            <Loading/>
            </>
          )}
    </>
      ) : (
        <>
        <div className="AllQuestion-Box">
            <h2>{message}</h2>
        </div>
        </>
      )}                
            </div>
            <div className="Reponse-Container">
            <div className="ContactUs-infoBox" style={{height:'20%' , marginTop:'-0.5rem'}}>
                <label> السؤال </label>
                <input type="text" value={question} onChange={(event) => setQuestion(event.target.value)}  />
            </div>
            <div className="ContactUs-infoBox" style={{height:'20%' , marginTop:'-0.5rem'}}>
                <label>إسم السائل</label>
                <input type='text' value={name} onChange={(event) => setName(event.target.value)} />
            </div>
            <div className="ContactUs-MessageBox" style={{height:'50%'}}>
                <label>الإجابة</label>
                <textarea value={reponse} onChange={(event) => setreponse(event.target.value)}/>
            </div>
            <div onClick={Send} className="ContactUs-Btn" style={{marginLeft:'4rem' , marginTop:'0.5rem'}}>
                <span>
                إرسال
                </span>
            </div>
            </div>
        
        </>
    )
}

export default Faq
