import React, { useEffect, useState } from "react";
import API from "../../api/Api";
import Loading from "../../components/loading/Loading";
import Logo from "../../components/Logo/Logo";
import Navbar from "../../components/Navbar/Navbar";
import RightBar from "../../components/RightBar/RightBar";
import Style from "../../components/styles/Style";
import "./Truste.css";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../../components/Navbar/NavbarMobile";
import Footer from "../../components/Footer/Footer";

const Truste = () => {
  const [message, setMessage] = useState();
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    API.get("api/GetTrust").then((res) => {
      if (res.data.AllData.length === 0) {
        setMessage("لا يوجد شهادات");
      } else {
        setData(res.data.AllData);
        setLoading(true);
      }
    });
  }, []);
  const URL = "http://3.144.176.75/";

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  return (
    <>
      <Mobile>
        <NavbarMobile />
        <div className="Truste-Container-Mobile">
          {!message ? (
              Data.reverse().map((img) => (
                <>
                <div className="Truste-Box-Mobile">
                <img src={`${URL}${img.image}`} alt=""/>
               </div>
                </>
              ))
          ) : (
            <>
              <Loading/>
            </>
          )}
        </div>
          <Footer/>
      </Mobile>
      <Desktop>
        <Logo />
        <Navbar />
        <div style={Style}>
          <RightBar />
          <div className="Truste-Container">
            <div>
              <h1>تعليقات و رسائل لحالات شفاء </h1>
            </div>
            {!message ? (
              <>
                {loading ? (
                  Data.reverse().map((img) => (
                    <>
                      <div className="Truste-Box">
                        <img src={`${URL}${img.image}`} alt="" />
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <Loading />
                  </>
                )}
              </>
            ) : (
              <>
                <div className="GetAudio-Container">
                  <h1> {message} </h1>
                </div>
              </>
            )}
          </div>
        </div>
      </Desktop>
    </>
  );
};

export default Truste;
