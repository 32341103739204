import React, { useEffect ,  useState , useCallback } from 'react';
import './Login.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from '../../../api/Api';
import { setLoggedIn } from '../../../store/store';
import { useMediaQuery } from 'react-responsive';


const Login = () => {
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [message, setMessage] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

   

    useEffect(() => {
        const Token = sessionStorage.getItem('Token')
        if (Token) {
          setLoggedIn()
          navigate('/Admin-dashbord')
        }
      }, )
      const loggedIn = useCallback(() =>
        dispatch(setLoggedIn()), [dispatch])

      const navigate = (path) => {
        history.push(path)
      }

      const isLoggedIn = sessionStorage.getItem("token");
      if(isLoggedIn) {
          navigate('/Admin-dashbord')
      }


      const submit = () => {
        const data = { username, password }
    API.post('/api/Signin', data)
      .then((ok) => {
        if (ok.data.error === true) {
          return setMessage(ok.data.message)
        } else {
          loggedIn();
          sessionStorage.setItem('token', ok.data.Token);
          sessionStorage.setItem('Username', ok.data.username);
          navigate('/Admin-dashbord')

        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
   
    const Desktop = ({ children }) => {
      const isDesktop = useMediaQuery({ minWidth: 992 })
      return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 767 })
      return isMobile ? children : null
    }


    return (
        <>
        <Mobile>
        <div style={{width:'100%' , height:'100vh' , background:"#559CA2" , display:'flex' , justifyContent:"center" , alignItems:'center'}}>
                <div className="Login-Form-Mobile">
                {message && (
                        <span style={{color:'red'}}> {message} </span>
                    )}
                    <label> إسم المستخدم</label>
                    <input value={username} onChange={(event) => setUsername(event.target.value)} type="text" placeholder="exemple ..." />
                    <label>كلمة السر</label>
                    <input value={password} onChange={(event) => setPassword(event.target.value)} placeholder="*****" type="password"/>
                    <button onClick={submit}>الدخول</button>
                    
                </div>
            </div>  
        </Mobile>
        <Desktop>
        <div style={{width:'100%' , height:'100vh' , background:"#559CA2" , display:'flex' , justifyContent:"center" , alignItems:'center'}}>
                <div className="Login-Form">
                {message && (
                        <span style={{color:'red'}}> {message} </span>
                    )}
                    <label> إسم المستخدم</label>
                    <input value={username} onChange={(event) => setUsername(event.target.value)} type="text" placeholder="exemple ..." />
                    <label>كلمة السر</label>
                    <input value={password} onChange={(event) => setPassword(event.target.value)} placeholder="*****" type="password"/>
                    <button onClick={submit}>الدخول</button>
                    
                </div>
            </div>  
        </Desktop>
           
        </>
    )
}

export default Login
