import React , { useEffect, useState } from 'react';
import API from "../../../api/Api";
import Loading from "../../loading/Loading";
import './styles.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const GetArticle = () => {
    const [allArticle, setAllArticle] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();

    useEffect(() => {
        API.get("api/GetPosts").then((res) => {
          if (res.data.AllData.length === 0) {
            setMessage("لا يوجد مقالات");
          } else {
            setAllArticle(res.data.AllData);
            setLoading(true);
          }
        });
      }, []);
      const DeletePost = (_id) => {
        API.delete(`api/DeletePost/${_id}`).then(ok => {
            API.get("api/GetAudio")
          .then((res) => {
            if (res.data.length === 0) {
              setMessage("لا يوجد صوتيات");
            } else {
              setAllArticle(res.data.AllData);
              setLoading(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        })
      };
      
      const URI = 'http://3.144.176.75/'


      console.log(allArticle)
    return (
        <>
        {!message ? (
            <div className="GetArticle-Container">
                {loading ? (
                    allArticle.map((article ) => (
                <div key={article._id} className="Article-">
                    <div onClick={() => {
                  DeletePost(article._id)
                }} className="Article--Delete">
                        <HighlightOffIcon fontSize={'large'}/>
                    </div>
                    <div className="Article--Title">
                        <span>{article.title} </span>
                    </div>
                    <div className="Article--Image">
                        <img src={`${URI}${article.image}`} alt="" />
                    </div>
                </div>
                    ))
                ) :( 
                    <Loading/>
                )}
            </div>
        ) : (
                <div className="GetArticle-Container">
                    <h1> {message} </h1>
                </div>
        )}
            
        </>
    )
}

export default GetArticle
