import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <div className="Footer-Container">
                <span>
                   جميع الحقوق محفوظة 
                    <Link to="/">لموقع الغريب الموصلي </Link>
                </span>
            </div>
        </>
    )
}

export default Footer
