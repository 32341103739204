import React, { useState } from 'react';
import './Dashbord.css';
import AddArticle from './../Pages/AddArticle';
import GetArticle from '../Pages/GetArticle';
import AddAudio from '../Pages/AddAudio';
import GetAudio from './../Pages/GetAudio';
import Faq from '../Pages/Faq';
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { setLoggedOut } from "../../../store/store";
import { Link, useHistory } from "react-router-dom";
import Eroor404 from '../../../pages/Eroor404';
import { useMediaQuery } from 'react-responsive';
import AddImage from '../Pages/AddImage';
import AddFatwa from '../Pages/AddFatwa';



const Dashbord = () => {
    const [one, setOne] = useState(true);
    const [two , setTwo] =useState(false);
    const [three , setThree] =useState(false);
    const [four , setFour] =useState(false);
    const [five , setFive] =useState(false);
    const [six , setSix] =useState(false);
    const [seven , setSeven] = useState(false)

    const history = useHistory();
    const dispatch = useDispatch();
    const isLoggedIn = sessionStorage.getItem("token");
    const loggedOut = useCallback(() => dispatch(setLoggedOut()), [dispatch]);

    const navigate = (path) => {
        history.push(path);
      };
    const Logout = () => {
        loggedOut();
        sessionStorage.setItem("token", "");
        navigate("/alghareeb-admin");
    }
    const PageOne = () => {
        setOne(true);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
    }
    const PageTwo = () => {
        setOne(false);
        setTwo(true);
        setThree(false);
        setFour(false);
        setFive(false);
    }
    const PageThree = () => {
        setOne(false);
        setTwo(false);
        setThree(true);
        setFour(false);
        setFive(false);
    }
    const PageFour = () => {
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(true);
        setFive(false);
    }
    const PageFive = () => {
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(true);
    }
    const PageSix = () => {
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
        setSix(true)
    }
    const PageSeven = () => {
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
        setSix(false)
        setSeven(true)
    }


    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
      }

      const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
      }
    return (
        <>
        <Mobile>
        {isLoggedIn && (
            <div className="Dashbord-Container-Mobile">
                <div className="Dashbord-Sidebar-Mobile">
                    <Link  to="/" style={{textDecoration:"none"}} className="List-Box">
                        <h3>زيارة الموقع</h3>
                    </Link>
                    <div onClick={PageOne} className="List-Box">
                        <h3>إضافة مقالة</h3>
                    </div>
                    <div onClick={PageTwo} className="List-Box">
                        <h3>جميع المقالات</h3>
                    </div>
                    <div onClick={PageThree} className="List-Box">
                        <h3>إضافة صوتيات</h3>
                    </div>
                    <div onClick={PageFour} className="List-Box">
                        <h3>جميع الصوتيات</h3>
                    </div>
                    <div onClick={PageFive} className="List-Box">
                        <h3>أسئلة</h3>
                    </div>    
                    <div onClick={PageSix} className="List-Box">
                        <h3>إضافة شهادات</h3>
                    </div> 
                    <div onClick={PageSeven} className="List-Box">
                        <h3>إضافة فتوى</h3>
                    </div>      
                    <div onClick={Logout} className="List-Box">
                        <h3>تسجيل الخروج</h3>
                    </div>    
                </div>
                <div className="Dashbord-Page-Mobile">
                    {one && <AddArticle/>}
                    {two && <GetArticle/>}
                    {three && <AddAudio/>}
                    {four && <GetAudio/>}
                    {five && <Faq/>}
                    {six && <AddImage/>}
                    {seven && <AddFatwa/>}
                </div>
            </div>
        )}
        {
            !isLoggedIn && (
                <Eroor404/>
            )
        }
        </Mobile>
        <Desktop>
        {isLoggedIn && (
            <div className="Dashbord-Container">
                <div className="Dashbord-Sidebar">
                    <Link  to="/" style={{textDecoration:"none"}} className="List-Box">
                        <h3>زيارة الموقع</h3>
                    </Link>
                    <div onClick={PageOne} className="List-Box">
                        <h3>إضافة مقالة</h3>
                    </div>
                    <div onClick={PageTwo} className="List-Box">
                        <h3>جميع المقالات</h3>
                    </div>
                    <div onClick={PageThree} className="List-Box">
                        <h3>إضافة صوتيات</h3>
                    </div>
                    <div onClick={PageFour} className="List-Box">
                        <h3>جميع الصوتيات</h3>
                    </div>
                    <div onClick={PageFive} className="List-Box">
                        <h3>أسئلة</h3>
                    </div>
                    <div onClick={PageSix} className="List-Box">
                        <h3>إضافة شهادات</h3>
                    </div>      
                    <div onClick={PageSeven} className="List-Box">
                        <h3>إضافة فتوى</h3>
                    </div>      
                    <div onClick={Logout} className="List-Box">
                        <h3>تسجيل الخروج</h3>
                    </div>    
                </div>
                <div className="Dashbord-Page">
                    {one && <AddArticle/>}
                    {two && <GetArticle/>}
                    {three && <AddAudio/>}
                    {four && <GetAudio/>}
                    {five && <Faq/>}
                    {six && <AddImage/>}
                    {seven && <AddFatwa/>}
                </div>
            </div>
        )}
        {
            !isLoggedIn && (
                <Eroor404/>
            )
        }
        </Desktop>
       
            
        </>
    )
}

export default Dashbord
