import React from 'react'
import './Logo.css';
import LogoImage from './logo.jpg'


const Logo = () => {
    return (
        <>
            <div className="Logo-Container">
                <img src ={LogoImage} alt=""/>
            </div>
        </>
    )
}

export default Logo
