import React from 'react';
import './Baramj.css';
import Footer from '../../components/Footer/Footer'
import Logo from '../../components/Logo/Logo'
import Navbar from '../../components/Navbar/Navbar'
import RightBar from '../../components/RightBar/RightBar'
import Style from '../../components/styles/Style'
import NavbarMobile from '../../components/Navbar/NavbarMobile';
import { useMediaQuery } from 'react-responsive';
import StyleMobile from '../../components/styles/StyleMobile';


const Baramj = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
      }

      const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
      }

    return (
        <>
        <Mobile>
            <NavbarMobile/>
            <div style={StyleMobile}>
            <div className="Baramj-Container-Mobile">
                    <h1>
                    لا يوجد برامج حاليا
                    </h1>
                </div>
            </div>
            <Footer/>
        </Mobile>
        <Desktop>
        <Logo/>
            <Navbar/>
            <div style={Style}>
                <RightBar/>
                <div className="Baramj-Container">
                    <h1>
                    لا يوجد برامج حاليا
                    </h1>
                </div>
            </div>
            <Footer/>
        </Desktop>
            
        </>
    )
}

export default Baramj
