import React from 'react'
import { BrowserRouter , Route , Switch } from 'react-router-dom'
import Home from './pages/Home';
import Eroor404 from './pages/Eroor404';
import Dashbord from './components/admin/Dashbord/Dashbord';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PageDetails from './pages/RightBarPages/PageDetails';
import Audio from './pages/RightBarPages/Audio';
import Ask from './pages/RightBarPages/Ask';
import Fatawa from './pages/RightBarPages/Fatawa';
import Blog from './pages/RightBarPages/Blog';
import Baramj from './pages/RightBarPages/Baramj';
import Login from './components/admin/login/Login';
import Truste from './pages/RightBarPages/Truste';



const App = () => {
  return (
    <>
    
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/من-أنا " component={AboutUs}/>
          <Route exact path="/إتصل-بنا" component={ContactUs}/>
          <Route exact path ="/الصوتيات" component={Audio} />
          <Route exact path="/إسأل" component={Ask}/>
          <Route exact path='/التدوينات' component={Blog}/>
          <Route exact path='/برامج' component={Baramj}/>
          <Route exact path='/فتاوى' component={Fatawa}/>
          <Route exact path='/شهادات-حية' component={Truste}/>
          <Route exact path="/Admin-Dashbord" component={Dashbord}/>
          <Route exact path='/alghareeb-admin' component={Login}/>
          <Route exact path="/:id" component={PageDetails}/>
          <Route exact component={Eroor404}/>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
