import React, { useEffect, useState } from "react";
import "./Ask.css";
import Logo from "../../components/Logo/Logo";
import Navbar from "../../components/Navbar/Navbar";
import Style from "../../components/styles/Style";
import RightBar from "../../components/RightBar/RightBar";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/loading/Loading";
import API from "../../api/Api";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../../components/Navbar/NavbarMobile";
import StyleMobile from "../../components/styles/StyleMobile";

const Ask = () => {
  const [AllReponse, setAllReponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [name, setName] = useState("");
  const [question, setQuestion] = useState("");
  const [messagevalid, setMessagevalid] = useState();
  const [messageerr, setMessageerr] = useState();

  

 

  const Send = () => {


    const formData = new FormData();
    formData.set("name", name);
    formData.set("question", question);
    API.post("api/Ask", formData)
      .then((res) => {
        if (res.data.err === true) {
          setMessageerr(res.data.message);
          setTimeout(() => {
            setMessageerr(false);
          }, 3000);
        } else {
          setMessagevalid(res.data.message);
          setTimeout(() => {
            setMessagevalid(false);
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    API.get("api/GetAllReponse").then((res) => {
      if (res.data.AllReponse.length === 0) {
        setMessage("لا توجد أسئلة ");
      } else {
        setAllReponse(res.data.AllReponse);
        setLoading(true);
      }
    });
  }, []);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : isDesktop === null;
  };

  return (
    <>
    <Mobile>
      <NavbarMobile/>
    </Mobile>
        <Logo />
        <Navbar />
        <div style={Style}>
          <RightBar />
          <div className="Ask-Container">
            {messagevalid && (
              <div style={{ position: "absolute" }} className="MessageValid">
                <span> {messagevalid} </span>
              </div>
            )}

            <div className="Reply-Container">
              {!message ? (
                <div className="GetAudio-Container">
                  {loading ? (
                    AllReponse.map((Reponse) => (
                      <>
                        <div className="Reply-Box">
                          <div className="Reply-Information">
                            <h5> السؤال {Reponse.question}</h5>
                            <h5>السائل: {Reponse.name}</h5>
                          </div>
                          <div className="Reply-Message">
                            <p>{Reponse.reponse}</p>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      <div style={{ margin: "0 auto", marginTop: "14rem" }}>
                        <Loading />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="GetAudio-Container">
                    <h2
                      style={{
                        alignSelf: "center",
                        margin: "0 auto",
                        color: "white",
                      }}
                    >
                      {" "}
                      {message}{" "}
                    </h2>
                  </div>
                </>
              )}
            </div>
            <div className="ContactUs-Container" style={{ margin: "0 auto" }}>
              <h1>إسأل الشيخ</h1>
              {messageerr && (
                <span style={{ color: "red" }}> {messageerr} </span>
              )}
              {messagevalid && (
                <span style={{ color: "green" }}> {messagevalid} </span>
              )}
              <div className="ContactUs-infoBox">
                <label>اسمك</label>
                <input
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              <div className="ContactUs-infoBox">
                <label>السؤال </label>
                <input
                  type="text"
                  value={question}
                  onChange={(event) => setQuestion(event.target.value)}
                />
              </div>
              <div
                className="ContactUs-Btn"
                style={{ marginRight: "13rem", marginTop: "1rem" }}
                onClick={Send}
              >
                <span>إرسال</span>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        </>
  );
};

export default Ask;
