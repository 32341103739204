import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import "./Navbar.css";

const NavbarMobile = () => {
  const [PageNavigation, setPageNavigation] = useState(false);

  const Show = () => {
    setPageNavigation(true);
  };
  const Hide = () => {
    setPageNavigation(false);
  };
  return (
    <>
      {PageNavigation === false ? (
        <div className="Navbar-Container-Mobile">
          <div className="Navbar-Mobile-Logo">
            <span>الغريب الموصلي</span>
          </div>
          <div onClick={Show} className="Navbar-Mobile-Burger">
            <MenuRoundedIcon fontSize={"large"} />
          </div>
        </div>
      ) : (
        <div className="Navbar-Container-Mobile">
          <div className="Navbar-Mobile-Logo">
            <span>الغريب الموصلي</span>
          </div>
          <div onClick={Hide} className="Navbar-Mobile-Burger">
            <CloseRoundedIcon fontSize={"large"} />
          </div>
        </div>
      )}

      {PageNavigation && (
        <>
          <div className="Navbar-Mobile-Link">
            <Link to="/">الرئيسية</Link>
            <Link to="/من-أنا ">تعريف بلشيخ</Link>
            <Link to="/شهادات-حية">شهادات حية</Link>
            <Link to="/إتصل-بنا">إتصل بنا</Link>
            <Link to="/التدوينات">مقالات و بحوث الشيخ</Link>
            <Link to="/إسأل">أنت تسأل و الغريب يجيب</Link>
            <Link to="/الصوتيات">صوتيات الشيخ</Link>
            <Link to="/برامج">برامج علاجية</Link>
            <Link to="/فتاوى">فتاوى في الرقية الشرعية</Link>
          </div>
        </>
      )}
    </>
  );
};

export default NavbarMobile;
