import React, { useEffect, useState } from "react";
import API from "../../api/Api";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/loading/Loading";
import Logo from "../../components/Logo/Logo";
import Navbar from "../../components/Navbar/Navbar";
import RightBar from "../../components/RightBar/RightBar";
import Style from "../../components/styles/Style";
import "./Fatawa.css";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../../components/Navbar/NavbarMobile";
import StyleMobile from "../../components/styles/StyleMobile";

const Fatawa = () => {
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  useEffect(() => {
    API.get("api/GetFatwa").then((res) => {
      if (res.data.AllData.length === 0) {
        setMessage("لا يوجد فتاوى");
      } else {
        setData(res.data.AllData);
        setLoading(true);
      }
    });
  }, []);
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  return (
    <>
      <Mobile>
        <NavbarMobile />
        <div style={StyleMobile}>
          <div className="Fatwa-Container-Mobile">
            <h2>فتاوى في الرقية الشرعية </h2>
          
          {!message ? (
            <>
              {loading ? (
                Data.map((fatwa) => (
                  <>
                    <div className="fatawa-box-Mobile">
                      <div className="Title-fatwa-box-Mobile">
                        <span>
                          {" "}
                          {fatwa.name} <strong> : المفتي </strong>{" "}
                        </span>
                        <strong>: السؤال</strong>
                        <span>
                          {" "}
                          {fatwa.title} {" "}
                        </span>
                      </div>
                      <div className="Fatwa-article">
                        <h4>: الجواب</h4>
                        <p>{fatwa.article}</p>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="Title-fatwa-box">
                    <Loading />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="Fatwa-Container">
                <h1> {message} </h1>
              </div>
            </>
          )}
          </div>
        </div>
        <Footer/>
      </Mobile>
      <Desktop>
        <Logo />
        <Navbar />
        <div style={Style}>
          <RightBar />
          <div className="Fatwa-Container">
            <h1>فتاوى في الرقية الشرعية </h1>
            {!message ? (
              <>
                {loading ? (
                  Data.map((fatwa) => (
                    <>
                      <div className="fatawa-box">
                        <div className="Title-fatwa-box">
                          <span>
                            {" "}
                            {fatwa.name} <strong> : المفتي </strong>{" "}
                          </span>
                          <span>
                            {" "}
                            {fatwa.title} <strong>: السؤال</strong>{" "}
                          </span>
                        </div>
                        <div className="Fatwa-article">
                          <h4>: الجواب</h4>
                          <p>{fatwa.article}</p>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <div className="Title-fatwa-box">
                      <Loading />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="Fatwa-Container">
                  <h1> {message} </h1>
                </div>
              </>
            )}
          </div>
        </div>

        <Footer />
      </Desktop>
    </>
  );
};

export default Fatawa;
