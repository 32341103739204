import React, { useEffect, useState } from "react";
import "./BlogContent.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import Loading from "../loading/Loading";
import API from "../../api/Api";

const BlogContentMobile = () => {
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();

  useEffect(() => {
    API.get("api/GetPosts").then((res) => {
      if (res.data.AllData.length === 0) {
        setMessage("لا يوجد مقالات");
      } else {
        setData(res.data.AllData);
        setLoading(false);
      }
    });
  }, []);

  const URL = "http://3.144.176.75/";

  return (
    <>
      <div className="BlogContent-Container-Mobile">
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        )}
        <div className="Slider-Blog">
          <Carousel
            autoPlay={true}
            autoFocus={false}
            infiniteLoop={true}
            showThumbs={false}
          >
            {Data.slice(0, 3).map((post) => (
              <>
                <Link to={`${post._id}`} style={{ textDecoration: "none" }}>
                  <span style={{ color: "black", fontSize: "2rem" }}>
                    {post.title}
                  </span>
                  <img
                    className="Slider-Image-Mobile"
                    src={`${URL}${post.image}`}
                    alt={post.title}
                  />
                </Link>
              </>
            ))}
          </Carousel>
        </div>
        <div  className="Article-Container-Mobile">
          {Data.slice(-3).map((article) => (
            <>
              <Link  to={`${article._id}`} className="Article-Card-Mobile">
                <div  className="Article-Card-Image-Mobile">
                  <img src={`${URL}${article.image}`} alt={article.title} />
                </div>
                <div  className="Article-Content-Mobile">
                  <div className="Article-Content-Title">
                    <h3>{article.title}</h3>
                  </div>
                  <div className="Article-Content-Paragraphe">
                    <p>{article.article}</p>
                  </div>
                </div>
              </Link>
            </>
          ))}
        </div>
       
      </div>
      
    </>
  );
};

export default BlogContentMobile;
