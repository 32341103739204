import React, { useEffect, useState } from 'react';
import './SinglePage.css';
import Style from '../../components/styles/Style';
import Logo from '../../components/Logo/Logo';
import Navbar from '../../components/Navbar/Navbar';
import RightBar from '../../components/RightBar/RightBar';
import {  useParams } from 'react-router';
import API from '../../api/Api';
import Loading from '../../components/loading/Loading';
import { useMediaQuery } from 'react-responsive';
import NavbarMobile from '../../components/Navbar/NavbarMobile';
import StyleMobile from '../../components/styles/StyleMobile';
import Footer from '../../components/Footer/Footer'


const SinglePage = () => {
    const [Data , setData] = useState([]);
    const [message , setMessage] = useState();
    const [loading , setLoading] = useState(false);

    const id = useParams();
    const URL = 'http://3.144.176.75/'

    useEffect(() => {
        API.get("api/GetPosts").then((res) => {
            if(res.data.AllData.length === 0) {
                setMessage("vous avez pas poster une property");
            }else{
                setData(res.data.AllData.find(x => x._id === id.id));
                setLoading(true);
            }
            
          }).catch((err) => {
              console.log(err)
          })
        
    }, [])
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
      }

      const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
      }

    
    return (
        <>
        <Mobile>
        {!message ? (
            <>
          {loading ? (
                <div>
                <NavbarMobile />
                <div style={StyleMobile}>
                    <div className="SinglePage-Container-Mobile">
                        {!loading && (
                            <div style={{margin:'0 auto', marginTop:'2rem' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                                    <Loading/>
                                </div>
                        )}
                            {message ? (
                                <div style={{margin:'0 auto', marginTop:'2rem' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                                    <h1>لا يوجد مقالة في هذا الرابط </h1>
                                </div>
                            ) : (
                                <>
                                <div className="SinglePage-Image-Mobile">
                                 <img src={`${URL}${Data.image}`}  alt=''/> 
                            </div>
                            <div className="SinglePage-Title">
                                <h1>{Data.title}</h1>
                            </div>
                            <p>
                                {Data.article}
                            </p>
                                </>
                            )}
                        
                        
                    </div>
                </div>
            </div>
          ) : (
              <>
            <Logo />
            <Navbar />
            <div style={Style}>
                <RightBar/>
                <div className="SinglePage-Container">
                    <div style={{margin:"0 auto" ,width:"100%" , display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                         <Loading />
                    </div>
              </div>
            </div>
            </>
          )}
          </>
      ) : (
        <>
        
            <span>{message}</span>
            </>
       
      )}
      <div style={{background:"red" , top:"0"}} >
      <Footer/>
      </div>
        </Mobile>
        <Desktop>
        {!message ? (
        <div className="Allposts-container">
          <div className="Allposts-Filter"></div>
          <div className='Allposts-Card'>
          {loading ? (
                <div>
                <Logo />
                <Navbar />
                <div style={Style}>
                    <RightBar />
                    <div className="SinglePage-Container">
                        {!loading && (
                            <div style={{margin:'0 auto', marginTop:'2rem' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                                    <Loading/>
                                </div>
                        )}
                            {message ? (
                                <div style={{margin:'0 auto', marginTop:'2rem' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                                    <h1>لا يوجد مقالة في هذا الرابط </h1>
                                </div>
                            ) : (
                                <>
                                <div className="SinglePage-Image">
                                    
                                 <img src={`${URL}${Data.image}`}  alt=''/> 
                            </div>
                            <div className="SinglePage-Title">
                                {Data.title}
                            </div>
                            <p>
                                {Data.article}
                            </p>
                                </>
                            )}
                        
                        
                    </div>
                </div>
            </div>
            
          ) : (
              <>
            <Logo />
            <Navbar />
            <div style={Style}>
                <RightBar/>
                <div className="SinglePage-Container">
                    <div style={{margin:"0 auto" ,width:"100%" , display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                         <Loading />
                    </div>
              </div>
            </div>
            </>
          )}
          </div>
        </div>
      ) : (
        <>
        
            <span>{message}</span>
            </>
       
      )}
      <Footer/>
        </Desktop>
        
       
            
        </>
    )
}

export default SinglePage
