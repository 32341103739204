import React from "react";
import Footer from "../components/Footer/Footer";
import Logo from "../components/Logo/Logo";
import Navbar from "../components/Navbar/Navbar";
import RightBar from "../components/RightBar/RightBar";
import Style from "../components/styles/Style";
import "./AboutUs.css";
import { useMediaQuery } from 'react-responsive';
import NavbarMobile from "../components/Navbar/NavbarMobile";
import StyleMobile from "../components/styles/StyleMobile";

const AboutUs = () => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

  return (
    <>
    <Mobile>
      <NavbarMobile/>
      <div style={StyleMobile}>
      <div className="AboutUs-Container-Mobile">
          <h1>من هو الغريب الموصلي</h1>
          <p>
            هو عبد الرحمن سعد جار الله في 16 اكتوبر 1990 متخرج من احد اهم
            المعاهد الاسلامية في مدينة الموصل حافظ للقران الكريم كاملا حديث نبوي
            مجاز بالقران الكريم برواية عاصم (حفص وشعبة)
          </p>
          <h1>مساره</h1>
          <p>
            بدا الشيخ رحلته في المساجد مؤذنا في احد مساجد مدينه الموصل وذلك في
            عام 2007 ثم بدا بالامامه في عام 2010 ثم خطيبا مزاجا عام 2014 بالنسبه
            للقران الكريم فقد اجاز به الشيخ الاستاذ حسين المجاز بالقراءات العشر
            الصغرى والكبرى عن اهل الاثر الى رسول الله صلى الله عليه وسلم
            بالاضافه الى بعض الاجازات بالكتب الحديث واصول الفقه اما بخصوص الرقية
            الشرعية فقط بدا مدائها منذ عام 2012
          </p>
          <h1>مؤهلات علمية وعملية</h1>
          <p>
            معالج بالقران الكريم والطب التكميلي والحجامة لحالات المس والسحر
            والعين والحسد ايضا اعالج الحالات التالية (شريطة ان يكون سببها روحي
            وليس طبي) السرطان و الغده الدرقيه و الصرع والقولون العصبي و جميع
            الامراض التي تصيب الاطفال باذن الله تعالى عند الولاده ومنها التوحد و
            الصمم والخرس يجيد اللغة الانجليزية ومتوسطة في اللغة التركية
          </p>
          <h1>الكتب الفقهية</h1>
          <ul>
              <li>كتاب (عمدة الفقه) وهو كتاب في الفقه الحنبلي لمؤلفه ( إبن قدامة المقدسي ) -</li>
              <li>كتاب (عمدة الاحكام ) وهو من كتب الفقه الحديثي لمؤلفه ( الحافظ عبدالغني المقدسي ) -</li>
              <li>كتاب ( التسهيل في الفقه الحنبلي) وهو من تاليف الشيخ العلامة البعلي الحنبلي -</li>
              <li>ابواب متفرقة من كتاب زاد المستقن -</li>
          </ul>
          <h1> كتب الحديث</h1>
          <ul>
              <li>بلوغ المرام للعسقلاني -</li>
              <li>عمدة الاحكام للمقدسي -</li>
              <li>رياض الصالحين -</li>
          </ul>
          <h1>  كتب اصول الفقه</h1>
          <ul>
              <li>الورقات للجويني -</li>
              <li>لاصول من علم الاصول لابن عثيمين -</li>
              <li>تيسير الوصول الى قواعد الاصول ومعاقد الفصول -</li>
          </ul>
          <h1>كتب مصلح الحديث</h1>
          <ul>
              <li>متن البيقونينة تيسير علوم مصطلح الحديث للطحان -</li>
              <li>كتب النحو -</li>
              <li>متن الآجرومية لمحمد بن آجروم -</li>
          </ul>

        </div>
      </div>
      <Footer/>
    </Mobile>
<Desktop>
<Logo />
      <Navbar />
      <div style={Style}>
        <RightBar />
        <div className="AboutUs-Container">
          <h1>من هو الغريب الموصلي</h1>
          <p>
            هو عبد الرحمن سعد جار الله في 16 اكتوبر 1990 متخرج من احد اهم
            المعاهد الاسلامية في مدينة الموصل حافظ للقران الكريم كاملا حديث نبوي
            مجاز بالقران الكريم برواية عاصم (حفص وشعبة)
          </p>
          <h1>مساره</h1>
          <p>
            بدا الشيخ رحلته في المساجد مؤذنا في احد مساجد مدينه الموصل وذلك في
            عام 2007 ثم بدا بالامامه في عام 2010 ثم خطيبا مزاجا عام 2014 بالنسبه
            للقران الكريم فقد اجاز به الشيخ الاستاذ حسين المجاز بالقراءات العشر
            الصغرى والكبرى عن اهل الاثر الى رسول الله صلى الله عليه وسلم
            بالاضافه الى بعض الاجازات بالكتب الحديث واصول الفقه اما بخصوص الرقية
            الشرعية فقط بدا مدائها منذ عام 2012
          </p>
          <h1>مؤهلات علمية وعملية</h1>
          <p>
            معالج بالقران الكريم والطب التكميلي والحجامة لحالات المس والسحر
            والعين والحسد ايضا اعالج الحالات التالية (شريطة ان يكون سببها روحي
            وليس طبي) السرطان و الغده الدرقيه و الصرع والقولون العصبي و جميع
            الامراض التي تصيب الاطفال باذن الله تعالى عند الولاده ومنها التوحد و
            الصمم والخرس يجيد اللغة الانجليزية ومتوسطة في اللغة التركية
          </p>
          <h1>الكتب الفقهية</h1>
          <ul>
              <li>كتاب (عمدة الفقه) وهو كتاب في الفقه الحنبلي لمؤلفه ( إبن قدامة المقدسي ) -</li>
              <li>كتاب (عمدة الاحكام ) وهو من كتب الفقه الحديثي لمؤلفه ( الحافظ عبدالغني المقدسي ) -</li>
              <li>كتاب ( التسهيل في الفقه الحنبلي) وهو من تاليف الشيخ العلامة البعلي الحنبلي -</li>
              <li>ابواب متفرقة من كتاب زاد المستقن -</li>
          </ul>
          <h1> كتب الحديث</h1>
          <ul>
              <li>بلوغ المرام للعسقلاني -</li>
              <li>عمدة الاحكام للمقدسي -</li>
              <li>رياض الصالحين -</li>
          </ul>
          <h1>  كتب اصول الفقه</h1>
          <ul>
              <li>الورقات للجويني -</li>
              <li>لاصول من علم الاصول لابن عثيمين -</li>
              <li>تيسير الوصول الى قواعد الاصول ومعاقد الفصول -</li>
          </ul>
          <h1>كتب مصلح الحديث</h1>
          <ul>
              <li>متن البيقونينة تيسير علوم مصطلح الحديث للطحان -</li>
              <li>كتب النحو -</li>
              <li>متن الآجرومية لمحمد بن آجروم -</li>
          </ul>

        </div>
      </div>
      <Footer />
</Desktop>
      
    </>
  );
};

export default AboutUs;
