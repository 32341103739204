import React, { useEffect, useState } from "react";
import API from "../../../api/Api";
import Loading from "../../loading/Loading";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const GetAudio = () => {
  const [allvideo, setAllvideo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    API.get("api/GetAudio").then((res) => {
      if (res.data.AllData.length === 0) {
        setMessage("لا يوجد صوتيات");
      } else {
        setAllvideo(res.data.AllData);
        setLoading(true);
      }
    });
  }, []);

  const DeleteAudio = (_id) => {
    API.delete(`api/DeleteAudio/${_id}`).then(ok => {
        API.get("api/GetAudio")
      .then((res) => {
        if (res.data.length === 0) {
          setMessage("لا يوجد صوتيات");
        } else {
          setAllvideo(res.data.AllData);
          setLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    })
  };

  return (
    <>
      {!message ? (
        <div className="GetAudio-Container">
          {loading ? (
            allvideo.map((videos) => (
                <>
            
              <div key={videos._id} className="GetAudio-Video">
                <span onClick={() => {
                  DeleteAudio(videos._id)
                }}>
                <HighlightOffIcon fontSize={'large'}/>
                </span>
                <iframe
                  width="90%"
                  height="80%"
                  src={`https://www.youtube.com/embed/${videos.lien}`}
                  title="YouTube video player"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
              </>
            ))
          ) : (
            <>
              <Loading />
            </>
          )}
        </div>
      ) : (
        <>
          <div className="GetAudio-Container">
            <h1> {message} </h1>
          </div>
        </>
      )}
    </>
  );
};

export default GetAudio;
