import React from 'react'
import Footer from '../components/Footer/Footer';
import Logo from '../components/Logo/Logo';
import Navbar from '../components/Navbar/Navbar';
import RightBar from '../components/RightBar/RightBar';
import './ContactUs.css'
import { useMediaQuery } from 'react-responsive';
import NavbarMobile from '../components/Navbar/NavbarMobile';
import STYLE from '../components/styles/Style';
import StyleMobile from '../components/styles/StyleMobile'

const ContactUs = () => {
    
      const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
      }

      const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
      }
    return (
        <>
        <Mobile>
            <NavbarMobile/>
            <div style={StyleMobile}>
                <div className="ContactUs-Container-Mobile">
            <h1>
            إتصل بنا 
            </h1>
            <div className="ContactUs-infoBox-Mobile">
                <label>اسمك</label>
                <input></input>
            </div>
            <div className="ContactUs-infoBox-Mobile">
                <label>بريدك الإلكتروني</label>
                <input></input>
            </div>
            <div className="ContactUs-infoBox-Mobile">
                <label>الموضوع</label>
                <input></input>
            </div>
            <div className="ContactUs-MessageBox-Mobile">
                <label>رسالتك (اختياري)</label>
                <textarea>

                </textarea>
            </div>
            <div className="ContactUs-Btn-Mobile">
                <span>
                إرسال
                </span>
            </div>
                </div>
            </div>
            <Footer/>
        </Mobile>
        <Desktop>
        <Logo />
      <Navbar />
      <div style={STYLE}>
        <RightBar />
        <div className="ContactUs-Container">
            <h1>
            إتصل بنا 
            </h1>
            <div className="ContactUs-infoBox">
                <label>اسمك</label>
                <input></input>
            </div>
            <div className="ContactUs-infoBox">
                <label>بريدك الإلكتروني</label>
                <input></input>
            </div>
            <div className="ContactUs-infoBox">
                <label>الموضوع</label>
                <input></input>
            </div>
            <div className="ContactUs-MessageBox">
                <label>رسالتك (اختياري)</label>
                <textarea>

                </textarea>
            </div>
            <div className="ContactUs-Btn">
                <span>
                إرسال
                </span>
            </div>
        </div>
        
      </div>
      <Footer />
        </Desktop>
             
        </>
    )
}

export default ContactUs
