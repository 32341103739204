import React from 'react';
import './Navbar.css';
import {Link} from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            <div className="Navbar-Container">
                <nav>
                    <ul>
                        <li>
                            <Link to="/">الرئيسية</Link>
                        </li>
                        <li>
                            <Link to="/من-أنا ">
                                تعريف بلشيخ
                                </Link>
                        </li>
                        <li>
                            <Link to="/شهادات-حية">
                                شهادات حية
                                </Link>
                        </li>
                        <li>
                            <Link to="/إتصل-بنا">
                                إتصل بنا
                                </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Navbar
