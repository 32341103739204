import React from 'react'
import './Loading.css';
const Loading = () => {
    return (
        <>
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </>
    )
}

export default Loading
