import React, { useEffect, useState } from "react";
import "./Blog.css";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";
import Navbar from "../../components/Navbar/Navbar";
import RightBar from "../../components/RightBar/RightBar";
import Style from "../../components/styles/Style";
import { Link } from "react-router-dom";
import API from "../../api/Api";
import Loading from "../../components/loading/Loading";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../../components/Navbar/NavbarMobile";

const Blog = () => {
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState();
  const [noOfElement, setnoOfElment] = useState(3);

  useEffect(() => {
    API.get("api/GetPosts").then((res) => {
      if (res.data.AllData.length === 0) {
        setMessage("لا يوجد مقالات");
      } else {
        setData(res.data.AllData);
        setLoading(true);
      }
    });
  }, []);

  const slice = Data.slice(0, noOfElement);

  const LoadMore = () => {
    setnoOfElment(noOfElement + noOfElement);
  };
  const URI = "http://3.144.176.75/";

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  return (
    <>
      <Mobile>
        <NavbarMobile/>
        <div className="BlogContent-Container-Mobile">
            <div className="Article-Container-Mobile">
              {!message ? (
                <>
                  {loading ? (
                    slice.map((article) => (
                      <>
                       <Link  to={`${article._id}`} className="Article-Card-Mobile">
                <div  className="Article-Card-Image-Mobile">
                  <img src={`${URI}${article.image}`} alt={article.title}/>
                </div>
                <div  className="Article-Content-Mobile">
                  <div className="Article-Content-Title">
                    <h3>{article.title}</h3>
                  </div>
                  <div className="Article-Content-Paragraphe">
                    <p>{article.article}</p>
                  </div>
                </div>
              </Link>
                      </>
                    ))
                  ) : (
                    <>
                      <div className="Article-Card">
                        <Loading />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="GetAudio-Container">
                    <h1> {message} </h1>
                  </div>
                </>
              )}

              <div onClick={LoadMore} className="Load-btn">
                <span>... المزيد</span>
              </div>
            </div>
          </div>
          <Footer/>
      </Mobile>
      <Desktop>
        <Logo />
        <Navbar />
        <div style={Style}>
          <RightBar />
          <div className="Blog-Container">
            <div className="Article-Container">
              {!message ? (
                <>
                  {loading ? (
                    slice.map((article) => (
                      <>
                        <Link to={`${article._id}`} className="Article-Card">
                          <div className="Article-Content">
                            <div className="Article-Content-Title">
                              <h3>{article.title}</h3>
                            </div>
                            <div className="Article-Content-Paragraphe">
                              <p>{article.article}</p>
                            </div>
                          </div>
                          <div className="Article-Card-Image">
                            <img src={`${URI}${article.image}`} alt={article.title} />
                          </div>
                        </Link>
                      </>
                    ))
                  ) : (
                    <>
                      <div className="Article-Card">
                        <Loading />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="GetAudio-Container">
                    <h1> {message} </h1>
                  </div>
                </>
              )}

              <div onClick={LoadMore} className="Load-btn">
                <span>... المزيد</span>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Desktop>
    </>
  );
};

export default Blog;
