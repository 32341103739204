import React, { useEffect, useState } from "react";
import API from "../../api/Api";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/loading/Loading";
import Logo from "../../components/Logo/Logo";
import Navbar from "../../components/Navbar/Navbar";
import NavbarMobile from "../../components/Navbar/NavbarMobile";
import RightBar from "../../components/RightBar/RightBar";
import Style from "../../components/styles/Style";
import StyleMobile from "../../components/styles/StyleMobile";
import { useMediaQuery } from 'react-responsive';
import "./Audio.css";

const Audio = () => {
  //GetAudio
  const [allvideo, setAllvideo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [playlist, setPlaylist] = useState();


  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
 
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

  useEffect(() => {
    API.get("api/GetAudio").then((res) => {
      if (res.data.AllData.length === 0) {
        setMessage("لا يوجد صوتيات");
      } else {
        setAllvideo(res.data.AllData);
        setLoading(true);
      }
    });
  }, []);
  const Searching = async () => {
    if(playlist === ""){
      return  API.get("api/GetAudio").then((res) => {
        if (res.data.AllData.length === 0) {
          setMessage("لا يوجد صوتيات");
        } else {
          setAllvideo(res.data.AllData);
          setLoading(true);
        }
      });
    }else {
      if(playlist) {
        API.get("api/GetAudio").then((res) => {
          if (res.data.AllData.length === 0) {
            setMessage("لا يوجد صوتيات");
          } else {
            setLoading(true);
            setAllvideo(res.data.AllData.filter((x) => x.playlist === playlist));
          }
        });
      }
    }
  }

  return (
    <>
    <Mobile>
      <NavbarMobile/>
      <div style={StyleMobile}>
          <div className="Audio-Container-Mobile">
          <h1 style={{margin:"0 auto"}}>صوتيات الشيخ</h1>
           <div>
             <select style={{width:'100%' , cursor:'pointer' , height:'2rem' , textAlign:'center' , fontSize:'1rem' , fontWeight:'bold'}} value={playlist} onChange={(event) => setPlaylist(event.target.value)}>
               <option value=""> جميع الصوتيات </option>
               <option value="al3yn">رقية من العين</option>
               <option value="elshr">رقية من السحر </option>
               <option value="alhasd">رقية من الحسد</option>
             </select>
           </div>
             <button className="btn-Search" onClick={Searching}>
             بحث
             </button>
          {!message ? (
            <>
              {loading ? (
                allvideo.map((videos) => (
                  <>
                    <div className="Video-Container-Mobile">
                      <iframe
                        width="90%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${videos.lien}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <Loading />
                </>
              )}
            </>
          ) : (
            <>
              <div className="GetAudio-Container">
                <h1> {message} </h1>
              </div>
            </>
          )}
          </div>
      </div>
      <Footer/>
    </Mobile>
    <Desktop>
    <Logo />
      <Navbar />
      <div style={Style}>
        <RightBar />
        <div className="Audio-Container">
          <h1>صوتيات الشيخ</h1>
           <div>
             <select style={{width:'100%' , cursor:'pointer' , height:'2rem' , textAlign:'center' , fontSize:'1rem' , fontWeight:'bold'}} value={playlist} onChange={(event) => setPlaylist(event.target.value)}>
               <option value=""> جميع الصوتيات </option>
               <option value="al3yn">رقية من العين</option>
               <option value="elshr">رقية من السحر </option>
               <option value="alhasd">رقية من الحسد</option>
             </select>
           </div>
             <button className="btn-Search" onClick={Searching}>
             بحث
             </button>
          {!message ? (
            <>
              {loading ? (
                allvideo.map((videos) => (
                  <>
                    <div className="Video-Container">
                      <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videos.lien}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </>
                ))
              ) : (
                <>
                <div className="Video-Container">
                  <Loading />
                 </div> 
                </>
              )}
            </>
          ) : (
            <>
              <div className="GetAudio-Container">
                <h1> {message} </h1>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </Desktop>
    
    </>
  );
};

export default Audio;
